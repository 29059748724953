import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/mdx-page';
import AppStoreButton from '../components/app-store-button';
import PlayStoreButton from '../components/play-store-button';
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed';
export const _frontmatter = {
  "title": "Why Timelense?",
  "subtitle": ""
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`As some of you may know, I have been traveling the world as a digital nomad for more than two years. In the past two years, I have used Instagram, WhatsApp, iCloud photo albums, etc. to share my experiences with my friends and family. In fact, sharing pictures and videos of my travels by broadcasting across social media has become so easy that the process itself has become both addictive and mechanical - certainly not fulfilling, and more importantly - hollow and impersonal.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "-50vw",
          "marginRight": "-50vw",
          "width": "100vw",
          "left": "50%",
          "right": "50%",
          "maxWidth": "2000px",
          "marginBottom": "30px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/36bc4027e2f4bbb7bb1afed3503fe5fc/e523c/more-likes.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.436363636363637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAHcAY0Kv//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEAAQUCf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABgQAAIDAAAAAAAAAAAAAAAAAAABECFB/9oACAEBAAE/IchKj//aAAwDAQACAAMAAAAQ+/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EIDL/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGBABAAMBAAAAAAAAAAAAAAAAAQAxgXH/2gAIAQEAAT8QKdlrkJs4s//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "All we need is more likes. Illustration by @robotic_mermaid",
            "title": "All we need is more likes. Illustration by @robotic_mermaid",
            "src": "/static/36bc4027e2f4bbb7bb1afed3503fe5fc/cbe2c/more-likes.jpg",
            "srcSet": ["/static/36bc4027e2f4bbb7bb1afed3503fe5fc/bb312/more-likes.jpg 500w", "/static/36bc4027e2f4bbb7bb1afed3503fe5fc/4edc6/more-likes.jpg 1000w", "/static/36bc4027e2f4bbb7bb1afed3503fe5fc/cbe2c/more-likes.jpg 2000w", "/static/36bc4027e2f4bbb7bb1afed3503fe5fc/e35d9/more-likes.jpg 3000w", "/static/36bc4027e2f4bbb7bb1afed3503fe5fc/b28f0/more-likes.jpg 4000w", "/static/36bc4027e2f4bbb7bb1afed3503fe5fc/e523c/more-likes.jpg 5500w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The value and meaning were lost for me.`}</p>
    <p>{`This got my wheels turning. I want to share important updates with the people I care about. Social media is no longer built for connecting beyond likes and shares.`}</p>
    <p>{`There has always been a special place in my heart for postcards, and social media updates just don't match the feeling of sending and receiving a postcard. They are a way of communicating that still holds value intrinsically - a physical reminder of connections I've made and the people I hold close to my heart.`}</p>
    <p>{`That's why I still send postcards when I arrive at a destination. When I visit people, they show me the postcards they have received, and it opens up a conversation beyond what's on the card. We discuss life, our experiences, and our passions.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "-50vw",
          "marginRight": "-50vw",
          "width": "100vw",
          "left": "50%",
          "right": "50%",
          "maxWidth": "2000px",
          "marginBottom": "30px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/210261ed34954822f8cd9ee59892ec65/e523c/postcard-wall.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.436363636363637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wgARCAADABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBv/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAAB3cBzsK//xAAWEAEBAQAAAAAAAAAAAAAAAAABABD/2gAIAQEAAQUCwL//xAAVEQEBAAAAAAAAAAAAAAAAAAAAAf/aAAgBAwEBPwFI/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGhAAAQUBAAAAAAAAAAAAAAAAAAERITFxUf/aAAgBAQABPyFYcbb6ITp//9oADAMBAAIAAwAAABB/7//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQMBAT8QQ2DOX//EABURAQEAAAAAAAAAAAAAAAAAAAEA/9oACAECAQE/EIC//8QAGxAAAgIDAQAAAAAAAAAAAAAAAREAQVFhcYH/2gAIAQEAAT8QBkJq9xTwdswNEfRn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Postcard wall. Illustration by @robotic_mermaid",
            "title": "Postcard wall. Illustration by @robotic_mermaid",
            "src": "/static/210261ed34954822f8cd9ee59892ec65/cbe2c/postcard-wall.jpg",
            "srcSet": ["/static/210261ed34954822f8cd9ee59892ec65/bb312/postcard-wall.jpg 500w", "/static/210261ed34954822f8cd9ee59892ec65/4edc6/postcard-wall.jpg 1000w", "/static/210261ed34954822f8cd9ee59892ec65/cbe2c/postcard-wall.jpg 2000w", "/static/210261ed34954822f8cd9ee59892ec65/e35d9/postcard-wall.jpg 3000w", "/static/210261ed34954822f8cd9ee59892ec65/b28f0/postcard-wall.jpg 4000w", "/static/210261ed34954822f8cd9ee59892ec65/e523c/postcard-wall.jpg 5500w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`While I prefer postcards to social media, the biggest problem with postcards is the inability to share more than just a message. Postcards have the potential to be so much more than just a personal message - postcards should be able to share pictures, videos, and personal messages. But how?`}</p>
    <p>{`With a sprinkle of magic, a touch of augmented reality, and a little help from friends - that's how!`}</p>
    <p>{`We're beyond excited to introduce Timelense.`}</p>
    <p>{`Timelense is an app-based postcard design and communication platform, inspired by and built from a desire to share experiences in a personal way - to encourage meaningful sharing of 'status updates' with the people we love; a longing for a simple way to foster genuine connections beyond the pixels.`}</p>
    <p>{`How it works:`}</p>
    <ol>
      <li parentName="ol">{`Travel - and take some photos and videos while you're at it!`}</li>
      <li parentName="ol">{`Use Timelense to create a postcard - customize it with pics and videos from your trip.`}</li>
      <li parentName="ol">{`Once it's ready to send, we'll deliver your postcard for you.`}</li>
      <li parentName="ol">{`To activate the postcard, open Timelense to the camera screen (eg. Lens) and scan the QR code (located on the back of the postcard).`}</li>
      <li parentName="ol">{`Hold your phone above the front of the postcard, and voilà! The postcard will come to life with photos and videos.`}</li>
    </ol>
    <LiteYouTubeEmbed id="MYYrg2n-0To" title="Timelense video presentation" wrapperClass="yt-lite yt-lite-mdx" mdxType="LiteYouTubeEmbed" />
    <p>{`Want to give it a try?`}</p>
    <AppStoreButton mdxType="AppStoreButton" /> <PlayStoreButton mdxType="PlayStoreButton" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      